import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Pistols 4×6/leg`}</p>
    <p>{`Ball Hamstring Curls 4×12`}</p>
    <p>{`then,`}</p>
    <p>{`100/75 Calorie Assault Bike for time.`}</p>
    <p>{`*`}{`emom: 8-Goblet Squats (53/35)(rx+ 70/53)`}</p>
    <p>{`*`}{`First minute starts on the bike.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      